html {
  overflow-y: scroll; }

body {
  margin: 0;
  padding: 0;
  /* font-family: 'Roboto', sans-serif; */
  font-family: 'Open Sans';
  padding-right: 0 !important; }

.titleLink {
  text-decoration: none;
  color: #575e7b !important; }

.App {
  text-align: center; }

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px; }

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white; }

.App-title {
  font-size: 1.5em; }

.App-intro {
  font-size: large; }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.grid-list {
  columns: 4;
  list-style-type: none;
  text-align: left;
  padding-left: 0; }

.Splash__card {
  /* margin: 4em auto; */
  text-align: center;
  width: 100%; }

.Splash__inner {
  max-width: 400px;
  margin: 0 auto;
  padding: 0 20px; }

.full-height {
  height: 100vh; }

.Splash__container > div {
  height: 100%; }

.Splash__inner p {
  color: #575e7b; }

.Splash__container > div > div {
  display: flex;
  justify-content: center; }

.Splash__inner a {
  font-size: 14px;
  text-decoration: none;
  color: #323d64; }

button.Splash__button {
  background-color: #323d64;
  text-transform: capitalize;
  min-width: 230px;
  line-height: 2.5em;
  box-shadow: none;
  margin-top: 4em;
  font-size: 12px;
  font-weight: lighter; }
  button.Splash__button:hover {
    background-color: #20778f; }

.Splash__bg {
  background: linear-gradient(45deg, #029daf 0%, #323d64 100%);
  position: relative;
  /*
  background-image: url('waves.png');
  background-position: center;
  background-size: cover;
  position: relative;
  overflow: hidden;*/ }

.iebg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url("assets/login_image.png");
  background-position: center;
  background-size: cover; }

.react-datepicker {
  font-family: 'Open Sans' !important;
  border-radius: 0 !important; }

.Splash__container img {
  max-width: 296px;
  width: 100%;
  margin-bottom: 2em; }

#main-app-bar {
  color: white; }

#main-app-bar form label ~ div:before,
#main-app-bar form label ~ div:after {
  display: none !important; }

div.react-datepicker__current-month,
div.react-datepicker-time__header {
  font-size: 0.75rem;
  color: #575e7b; }

div.react-datepicker__header {
  border-color: #f0f3f6;
  background-color: transparent; }

div.react-datepicker__day-name,
div.react-datepicker__day,
div.react-datepicker__time-name {
  color: #575e7b; }

div.react-datepicker__day--disabled {
  color: #ccc; }

div.react-datepicker {
  font-size: 0.75rem;
  border: 0;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15); }

.react-datepicker__day.react-datepicker__day--selected,
.react-datepicker__day.react-datepicker__day--selected:hover {
  color: #ccc;
  background-color: #029daf;
  border-radius: 100%; }

div.react-datepicker__day {
  transition: all 0.3s; }

div.react-datepicker__day:hover {
  transition: all 0s;
  border-radius: 100%;
  background-color: #ccc; }

text.recharts-text.recharts-label,
span.recharts-legend-item-text {
  color: #575e7b;
  fill: #575e7b; }

.recharts-responsive-container {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0; }

.recharts-legend-item-text {
  font-size: 12px; }

.recharts-surface text {
  white-space: normal !important; }

.pie-label:first-of-type {
  font-size: 1.2vw;
  transform: translateY(-2.75%); }

.pie-label:last-of-type {
  font-size: 1vw;
  transform: translateY(2.75%); }

@media (max-width: 1296px) {
  .pie-label:first-of-type {
    font-size: 1.2vw;
    transform: translateY(-2.75%); }
  .pie-label:last-of-type {
    font-size: 1.2vw;
    transform: translateY(2.75%); } }

@media (min-width: 480px) and (max-width: 960px) {
  .pie-label:first-of-type {
    font-size: 2.2vw;
    transform: translateY(-3.75%); }
  .pie-label:last-of-type {
    font-size: 2.2vw;
    transform: translateY(3.75%); } }

@media (max-width: 480px) {
  .pie-label:first-of-type {
    font-size: 3vw;
    transform: translateY(-3.75%); }
  .pie-label:last-of-type {
    font-size: 3vw;
    transform: translateY(3.75%); } }

.custom-chart-tooltip {
  background-color: white;
  padding: 5px;
  font-size: 0.9rem;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12); }
  .custom-chart-tooltip p {
    margin: 0; }
  .custom-chart-tooltip > div {
    margin-bottom: 2px;
    padding-bottom: 2px;
    border-bottom: 1px solid #f0f3f6; }
    .custom-chart-tooltip > div:last-of-type {
      border: 0;
      padding: 0;
      margin: 0; }
  .custom-chart-tooltip tr td {
    margin-bottom: 2px;
    padding-bottom: 2px;
    border-bottom: 1px solid #f0f3f6; }
  .custom-chart-tooltip tr:last-of-type td {
    border: 0;
    padding: 0;
    margin: 0; }

.account_menu {
  -webkit-transition: bottom 0.65s ease, transform 0.65s ease;
  -moz-transition: bottom 0.65s ease, transform 0.65s ease;
  -ms-transition: bottom 0.65s ease, transform 0.65s ease;
  -o-transition: bottom 0.65s ease, transform 0.65s ease;
  transition: bottom 0.65s ease, transform 0.65s ease; }
  .account_menu li {
    padding-right: 4px; }
  .account_menu.open {
    transform: translateY(-100%); }
  .account_menu.closed .account_menu--collapse {
    transform: rotate(0.5turn); }

svg path.account_icon {
  fill: #029daf !important; }

.menu-tab svg path {
  transition: all 0.5s;
  fill: #8a8ba8 !important; }

.menu-tab svg {
  transition: all 0.5s; }

.menu-tab.active-tab svg path {
  fill: white !important; }

.menu-tab.active-tab svg {
  transform: scale(1.1); }

@media (max-width: 700px) {
  .app {
    margin-top: 77px; } }

.react-datepicker {
  animation: datepicker 0.3s cubic-bezier(0.55, 0, 0.1, 1); }

@keyframes datepicker {
  0% {
    transform: translateY(100px);
    opacity: 0; }
  100% {
    transform: none; } }

.Waves__container {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  overflow: hidden; }
  .Waves__container svg {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 60%;
    animation: bob 10s infinite ease-in-out; }

@keyframes bob {
  0% {
    transform: translateY(0%); }
  50% {
    transform: translateY(10%); }
  100% {
    transform: translateY(0%); } }

.wave {
  fill: rgba(255, 255, 255, 0.04);
  stroke: rgba(255, 255, 255, 0.3);
  stroke-width: 0.05; }

#wavegroup1 {
  animation: wave 12s linear infinite; }

#wavegroup2 {
  animation: wave 17s linear infinite;
  animation-direction: reverse; }

#wavegroup3 {
  animation: wave 7s linear infinite;
  opacity: 0.7; }

#wavegroup4 {
  animation: wave 8s linear infinite;
  opacity: 0.6; }

.gooeff {
  filter: url(#goo); }

.fullscreen-summary {
  animation: full 0.5s cubic-bezier(0.55, 0, 0.1, 1);
  position: fixed !important;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 99999; }
  .fullscreen-summary .chart-wrap {
    padding-bottom: 65vh !important; }

@keyframes full {
  0% {
    transform: scale(0);
    opacity: 0; }
  100% {
    opacity: 1;
    transform: scale(1); } }

@keyframes wave {
  to {
    transform: translateX(-100%); } }

@media (max-width: 600px) {
  .Waves__container svg {
    height: auto;
    width: 100%; } }

table *,
table {
  min-width: 1px !important; }

.switch-button span {
  font-size: 0.75rem !important; }

.switch-button > span > span:first-of-type {
  height: 21px; }

.Map {
  align-items: center;
  display: flex;
  flex-direction: column; }
  .Map h1 {
    display: block;
    width: 100%; }
  .Map svg {
    height: 500px;
    width: 750px;
    left: 0; }

.Presentation.Map svg {
  height: 850px;
  width: 1275px; }

.hoverinfo {
  padding: 4px;
  border-radius: 1px;
  background-color: #fff;
  font-size: 0.9rem;
  text-align: left;
  border: 1px solid #ccc;
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12); }
  .hoverinfo h5 {
    color: #029daf;
    font-weight: bold;
    margin: 0 0 0.2rem 0;
    font-size: 0.9rem;
    padding: 0; }

.presentation-table.green table {
  background-color: #DDF7EB; }
  .presentation-table.green table thead tr th {
    background-color: #1CC778; }

.presentation-table.red table {
  background-color: #FEE7E2; }
  .presentation-table.red table thead tr th {
    background-color: #F8613E; }

.presentation-table table th {
  padding: 18px 46px; }

.presentation-table table td {
  padding: 12px 46px; }

.presentation-table table th,
.presentation-table table td {
  font-size: 20px !important;
  border-color: #fff; }

.order-book.presentation-table table th {
  color: white !important; }

.hayward-green-logo {
  width: 11%;
  position: fixed;
  left: 50px;
  top: 44px;
  z-index: 9999; }

/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Bootstrap */
.ResponsiveMap {
  width: 100%;
  height: 375px;
  max-width: 100%;
  max-height: 100%; }

/* Small devices */
@media (min-width: 500px) {
  .ResponsiveMap {
    width: 100%;
    height: 480px; } }

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .ResponsiveMap {
    width: 660px;
    height: 440px; } }

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .ResponsiveMap {
    width: 750px;
    height: 500px; }
  .Presentation.ResponsiveMap {
    height: 850px;
    width: 1275px; } }
